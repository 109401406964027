//alias:linq
import * as Generator from "./generators";
import * as Utils from "./utilities";
import * as Iterator from "./iterators";
/** Converts any Iterable<T> object into LINQ-able object */
function getEnumerable(TSource) {
    return new EnumerableImpl(TSource);
}
/** Generates <count> of <T> elements starting with <start>. T is any type which could be cast to number: number, enum, etc. */
function getRange(start, count) {
    return new EnumerableImpl(undefined, Generator.Range, [start, count]);
}
/** Repeat element <start> of type T <count> of times. */
function getRepeat(value, count) {
    return new EnumerableImpl(undefined, Generator.Repeat, [value, count]);
}
export { getEnumerable as linq, getEnumerable as asEnumerable, getEnumerable as from, getRange as range, getRepeat as repeat };
class EnumerableImpl {
    constructor(target, factory, arg) {
        this._target = target;
        this._factory = factory;
        this._factoryArg = arg;
    }
    /** Returns JavaScript iterator */
    [Symbol.iterator]() {
        return (null != this._factory)
            ? this._factory.apply(this, this._factoryArg)
            : this._target[Symbol.iterator]();
    }
    /** Returns C# style enumerator */
    getEnumerator() {
        return new Iterator.CSharpEnumerator(this[Symbol.iterator]());
    }
    aggregate(seed, func = Utils.selfFn, resultSelector = Utils.selfFn) {
        let zero;
        let method;
        let selector;
        if (Utils.CONST_FUNCTION === typeof seed) {
            method = seed;
            selector = func;
        }
        else {
            zero = seed;
            method = func;
            selector = resultSelector;
        }
        let result = zero;
        for (let value of this) {
            if (!result)
                result = Utils.getDefaultVal(typeof (value));
            result = method(result, value);
        }
        return selector(result);
    }
    all(predicate = Utils.trueFn) {
        for (let value of this) {
            if (!predicate(value))
                return false;
        }
        return true;
    }
    any(predicate) {
        let iterator;
        // Check if at least one exist
        if (!predicate && (iterator = this._target[Symbol.iterator]())) {
            return !iterator.next().done;
        }
        // Check if any satisfy the criteria
        for (let value of this) {
            if (predicate(value)) {
                return true;
            }
        }
        return false;
    }
    average(func = Utils.selfFn) {
        let sum = 0, count = 0;
        for (let value of this) {
            sum += func(value);
            count++;
        }
        return sum / count;
    }
    contains(value, equal = (a, b) => a === b) {
        for (let item of this) {
            if (equal(item, value)) {
                return true;
            }
        }
        return false;
    }
    count(predicate) {
        let count = 0;
        if (predicate) {
            for (let value of this) {
                if (predicate(value)) {
                    count++;
                }
            }
        }
        else if (this._target && this._target[Utils.CONST_LENGTH]) {
            count = this._target[Utils.CONST_LENGTH];
        }
        else {
            for (let value of this)
                count++;
        }
        return count;
    }
    max(transform = Utils.selfFn) {
        let value, max, hasValue = false;
        for (let item of this) {
            value = transform(item);
            if (hasValue) {
                if (max < value)
                    max = value;
            }
            else {
                max = value;
                hasValue = true;
            }
        }
        if (!hasValue)
            throw Utils.CONST_NO_ELEMENTS;
        return max;
    }
    min(transform = Utils.selfFn) {
        let value, min, hasValue = false;
        for (let item of this) {
            value = transform(item);
            if (hasValue) {
                if (min > value)
                    min = value;
            }
            else {
                min = value;
                hasValue = true;
            }
        }
        if (!hasValue)
            throw Utils.CONST_NO_ELEMENTS;
        return min;
    }
    elementAt(index) {
        if (Array.isArray(this._target)) {
            if (0 > index || this._target[Utils.CONST_LENGTH] <= index)
                throw Utils.CONST_OUTOFRANGE;
            return this._target[index];
        }
        let count = 0;
        for (let value of this) {
            if (index > count++)
                continue;
            return value;
        }
        throw Utils.CONST_OUTOFRANGE;
    }
    elementAtOrDefault(index) {
        if (Array.isArray(this._target)) {
            let length = this._target[Utils.CONST_LENGTH];
            if (0 > index || length <= index) {
                let value = this._target[0];
                return 0 < length
                    ? Utils.getDefaultVal(typeof (value), value)
                    : undefined;
            }
            return this._target[index];
        }
        let value, count = 0;
        for (let item of this) {
            if (index === count++)
                return item;
            value = item;
        }
        return Utils.getDefaultVal(typeof value, value); // Last good value
    }
    first(predicate = Utils.trueFn) {
        for (let value of this) {
            if (predicate(value))
                return value;
        }
        throw Utils.CONST_NOTHING_FOUND;
    }
    firstOrDefault(predicate = Utils.trueFn) {
        let value;
        for (let item of this) {
            value = item;
            if (predicate(item))
                return item;
        }
        return Utils.getDefaultVal(typeof value); // Last good value
    }
    last(predicate = Utils.trueFn) {
        let value, found = false;
        for (let item of this) {
            if (predicate(item)) {
                value = item;
                found = true;
            }
        }
        if (!found)
            throw Utils.CONST_NOTHING_FOUND;
        return value;
    }
    lastOrDefault(predicate = Utils.trueFn) {
        let value, lastKnown, found = false;
        for (let item of this) {
            if (predicate(item)) {
                value = item;
                found = true;
            }
            lastKnown = item;
        }
        return (found) ? value : Utils.getDefaultVal(typeof lastKnown);
    }
    sequenceEqual(other, equal = (a, b) => a === b) {
        let res1, res2;
        let it1 = this[Symbol.iterator]();
        let it2 = other[Symbol.iterator]();
        while (true) {
            res1 = it1.next();
            res2 = it2.next();
            if (res1.done && res2.done)
                return true;
            if ((res1.done != res2.done) || !equal(res1.value, res2.value))
                return false;
        }
        ;
    }
    single(predicate = Utils.trueFn) {
        let value, hasValue = false;
        for (let item of this) {
            if (predicate(item)) {
                if (!hasValue) {
                    value = item;
                    hasValue = true;
                }
                else
                    throw Utils.CONST_TOO_MANY;
            }
        }
        if (hasValue)
            return value;
        throw Utils.CONST_NOTHING_FOUND;
    }
    singleOrDefault(predicate = Utils.trueFn) {
        let value, lastKnown, hasValue = false;
        for (let item of this) {
            if (predicate(item)) {
                if (!hasValue) {
                    value = item;
                    hasValue = true;
                }
                else
                    throw Utils.CONST_TOO_MANY;
            }
            lastKnown = item;
        }
        return (hasValue) ? value : Utils.getDefaultVal(typeof lastKnown);
    }
    sum(transform = Utils.selfFn) {
        let sum = 0;
        for (let value of this)
            sum += transform(value);
        return sum;
    }
    toArray() {
        let array = [];
        for (let value of this)
            array.push(value);
        return array;
    }
    toMap(keySelector, elementSelector = Utils.selfFn) {
        let dictionary = new Map();
        for (let value of this)
            dictionary.set(keySelector(value), elementSelector(value));
        return dictionary;
    }
    toSet(keySelector) {
        let result = new Set();
        for (let value of this)
            result.add(keySelector(value));
        return result;
    }
    cast() {
        // TODO: Remove any once TypeScript 2.0 out
        return this;
    }
    //-------------------------------------------------------------------------
    //  Deferred execution methods
    //-------------------------------------------------------------------------
    defaultIfEmpty(defaultValue = undefined) {
        return new EnumerableImpl(undefined, Generator.DefaultIfEmpty, [this, defaultValue]);
    }
    concat(second) {
        return new EnumerableImpl(undefined, Generator.Concat, [this, second]);
    }
    chunkBy(keySelect, elementSelector = Utils.selfFn, resultSelector = (a, b) => b) {
        return new EnumerableImpl(undefined, Generator.ChunkBy, [this, keySelect, elementSelector, resultSelector]);
    }
    distinct(keySelector) {
        if (keySelector)
            return new EnumerableImpl(undefined, Generator.Distinct, [this, keySelector]);
        else
            return new EnumerableImpl(undefined, Generator.DistinctFast, [this]);
    }
    except(other, keySelector) {
        return new EnumerableImpl(undefined, Generator.Intersect, [this, Utils.getKeys(other, keySelector), true, keySelector]);
    }
    groupBy(selKey, selElement = Utils.selfFn, selResult = Utils.defGrouping) {
        let map = Utils.getKeyedMap(this, selKey, selElement);
        return new EnumerableImpl(undefined, Generator.GroupBy, [map, selResult]);
    }
    groupJoin(inner, oKeySelect, iKeySelect, resultSelector = Utils.defGrouping) {
        return new EnumerableImpl(undefined, Generator.GroupJoin, [this, oKeySelect, resultSelector, Utils.getKeyedMapFast(inner, iKeySelect)]);
    }
    intersect(other, keySelector) {
        return new EnumerableImpl(undefined, Generator.Intersect, [this, Utils.getKeys(other, keySelector), false, keySelector]);
    }
    join(inner, oSelector, iSelector, transform) {
        return new EnumerableImpl(undefined, Generator.Join, [this, oSelector, transform, Utils.getKeyedMapFast(inner, iSelector)]);
    }
    ofType(obj) {
        let typeName;
        switch (obj) {
            case Number:
                typeName = Utils.CONST_NUMBER;
                break;
            case Boolean:
                typeName = Utils.CONST_BOOLEAN;
                break;
            case String:
                typeName = Utils.CONST_STRING;
                break;
            case Symbol:
                typeName = Utils.CONST_SYMBOL;
                break;
            default:
                typeName = undefined;
        }
        return new EnumerableImpl(undefined, Generator.OfType, [this, obj, typeName]);
    }
    orderBy(keySelect = Utils.selfFn, equal = (a, b) => a - b) {
        return new OrderedLinq(this, (array) => Generator.Forward(array), (a, b) => equal(keySelect(a), keySelect(b)));
    }
    orderByDescending(keySelect = Utils.selfFn, equal = (a, b) => a - b) {
        return new OrderedLinq(this, (array) => Generator.Reverse(array), (a, b) => equal(keySelect(a), keySelect(b)));
    }
    thenBy(keySelect = Utils.selfFn, equal = (a, b) => a - b) {
        if (this instanceof OrderedLinq) {
            let superEqual = this.equal;
            this.equal = (a, b) => {
                let result = superEqual(a, b);
                return (0 != result) ? result : equal(keySelect(a), keySelect(b));
            };
            return this;
        }
        else
            return new OrderedLinq(this, (array) => Generator.Forward(array), (a, b) => equal(keySelect(a), keySelect(b)));
    }
    thenByDescending(keySelect = Utils.selfFn, equal = (a, b) => a - b) {
        if (this instanceof OrderedLinq) {
            let superEqual = this.equal;
            this.equal = (a, b) => {
                let result = superEqual(a, b);
                return (0 != result) ? result : equal(keySelect(a), keySelect(b));
            };
            return this;
        }
        else
            return new OrderedLinq(this, (array) => Generator.Reverse(array), (a, b) => equal(keySelect(a), keySelect(b)));
    }
    range(start, count) {
        return new EnumerableImpl(undefined, Generator.Range, [start, count]);
    }
    repeat(element, count) {
        return new EnumerableImpl(undefined, Generator.Repeat, [element, count]);
    }
    reverse() {
        let array = Array.isArray(this._target) ? this._target : this.toArray();
        return new EnumerableImpl(undefined, Generator.Reverse, [array]);
    }
    select(transform) {
        return new EnumerableImpl(undefined, Generator.Select, [this, transform]);
    }
    selectMany(selector = Utils.selfFn, result = (x, s) => s) {
        return new EnumerableImpl(undefined, Generator.SelectMany, [this, selector, result]);
    }
    skip(skip) {
        return new EnumerableImpl(undefined, Generator.Skip, [this, skip]);
    }
    skipWhile(predicate) {
        return new EnumerableImpl(undefined, Generator.SkipWhile, [this, predicate]);
    }
    take(take) {
        return new EnumerableImpl(undefined, Generator.TakeWhile, [this, (a, n) => take > n]);
    }
    takeWhile(predicate) {
        return new EnumerableImpl(undefined, Generator.TakeWhile, [this, predicate]);
    }
    union(second, keySelector) {
        if (keySelector)
            return new EnumerableImpl(undefined, Generator.Union, [this, second, keySelector]);
        return new EnumerableImpl(undefined, Generator.UnionFast, [this, second]);
    }
    where(predicate = Utils.trueFn) {
        return new EnumerableImpl(undefined, Generator.Where, [this, predicate]);
    }
    zip(second, func) {
        return new EnumerableImpl(undefined, Generator.Zip, [this, second, func]);
    }
}
class OrderedLinq extends EnumerableImpl {
    constructor(target, factory, equal) {
        super(target, factory);
        this.equal = equal;
    }
    [Symbol.iterator]() {
        if (Utils.CONST_UNDEFINED === typeof this._factoryArg) {
            this._factoryArg = this._target.toArray();
            this._factoryArg.sort(this.equal);
        }
        return this._factory(this._factoryArg);
    }
}
