//summary: Методы общего назначения
//alias:   Ragtime.Misc
import * as Error from "../Errors/Error";
import { createFault } from "Ragtime.DataService";
/** Загружаем css-файл. (путем создания элемента link) */
export function loadCss(url) {
    const links = document.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
        if (links[i].href === url)
            return;
    }
    const ls = document.createElement('link');
    ls.type = "text/css";
    ls.rel = "stylesheet";
    ls.href = url;
    document.getElementsByTagName('head')[0].appendChild(ls);
}
/** Принимаем на вход html-строку, возвращаем DOM */
export function loadTemplate(template) {
    const div = document.createElement("div");
    div.innerHTML = template.trim();
    const result = div.firstChild;
    div.removeChild(result);
    return result;
}
/** Динамически загружаем указанный модуль, или null */
export async function loadModule(name) {
    const parts = name.split('/');
    const unitName = parts[0];
    const scriptName = `/${unitName}/${unitName}.js?` + window.ragtime.version;
    parts.shift();
    const entryPoint = parts.map(_ => _.split('.').join('_')).join("_");
    return (await import(scriptName))[entryPoint];
}
/** Загружаем модуль и возвращаем тип указанного класса. При ошибке - исключение */
export async function loadClass(moduleName, className) {
    const module = await loadModule(moduleName);
    if (!module)
        Error.Operation.throw("227F6613A496", "Can not load module: " + moduleName);
    let result = module;
    for (const name of className.split('.')) {
        result = result[name];
        Error.Operation.throwIf(!result, "3E6EFBB32911", "Class not found: " + className);
    }
    return result;
}
/** Загружаем указанный модуль и вызываем указанную команду. Параметры не передаем, результат игнорируем */
export async function loadAndExecute(moduleName, commandName) {
    (await loadModule(moduleName))[commandName].onExecute();
}
/** Загружаем объект по указанному адресу, затем десериализуем его и возвращаем */
export async function loadObject(url) {
    let fetchResult = await fetch(url, {
        method: "GET",
        cache: "no-cache",
        credentials: "include",
    });
    if (fetchResult.ok)
        return await fetchResult.json();
    else {
        let fault = createFault(fetchResult.status, fetchResult.statusText, await fetchResult.text());
        throw new Error.Operation("06C4D0F4F4B8", fault.message);
    }
}
/** Берем строку, в которой описан стиль, разбираем ее, и применяем к to */
export function parseStyle(to, value) {
    if (!value)
        return;
    for (let style of value.split(';')) {
        let iColon = style.indexOf(':');
        if (iColon > 0) {
            let name = style.substring(0, iColon).trim();
            let value = style.substring(iColon + 1).replace("!important", "").trim();
            to[name] = value;
        }
    }
}
/** Дополняем строку слева 2-мя нулями */
export function zPad2(value) {
    return ("00" + value).slice(-2);
}
/** Дополняем строку слева 4-мя нулями */
export function zPad4(value) {
    return ("0000" + value).slice(-4);
}
/** Сохраняем файл на клиентскую машину */
export function saveLocalFile(data, fileName) {
    downloadFile(window.URL.createObjectURL(data), fileName);
}
/** Сохраняем файл на клиентскую машину, имея на него ссылку */
export function downloadFile(url, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}
/** Сохраняем строку как xml-файл */
export function saveLocalXmlString(data, fileName) {
    saveLocalFile(new Blob([data], { type: "text/xml;charset=utf-8;" }), fileName);
}
/** Сохраняем base64-строку как файл */
export function saveLocalBinary(data, mimeType, fileName) {
    mimeType = mimeType || "application/octet-stream";
    saveLocalFile(new Blob([data], { type: mimeType }), fileName);
}
/** Сохраняем base64-строку как файл */
export function saveLocalJson(data, fileName, mimeType = "application/json") {
    saveLocalFile(new Blob([data], { type: mimeType }), fileName);
}
/** Запрашиваем у пользователя имя файла, загружаем файл и возвращаем его содержимое */
export function fetchLocalFile(type, accept = "*.*") {
    return new Promise((resolve, reject) => {
        try {
            loadLocalFile(accept, type, (blob, data) => resolve({ blob, data }));
        }
        catch (e) {
            reject(e);
        }
    });
}
/** Запрашиваем у пользователя имя файла, загружаем файл и возвращаем его содержимое */
export function loadLocalFile(accept, type, ready) {
    if (_loadFileInput)
        document.body.removeChild(_loadFileInput);
    _loadFileInput = document.createElement("INPUT");
    _loadFileInput.type = "file";
    if (accept)
        _loadFileInput.accept = accept;
    document.body.appendChild(_loadFileInput);
    _loadFileInput.onchange = e => {
        let files = e.target.files;
        if (files && files.length) {
            let file = files[0];
            let reader = new FileReader();
            reader.onload = function () {
                if (type === "text")
                    ready(file, this.result);
                else
                    ready(file, this.result.split(",")[1]);
            };
            if (type === "text")
                reader.readAsText(file);
            else
                reader.readAsDataURL(file);
        }
        document.body.removeChild(_loadFileInput);
        _loadFileInput = undefined;
    };
    _loadFileInput.click();
}
let _loadFileInput;
/** Создаем blob из строки base64 */
export function b64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}
/** Создаем ArrayBuffer из строки base64 */
export async function b64ToArrayBuffer(b64Data) {
    return await (await fetch("data:application/octet-binary;base64," + b64Data)).arrayBuffer();
}
/** Откладываем выполнение на delay, предотвращаем слишком частые вызовы.
 * Использование:
 
   1. OnSomething = debounce(() => console.log('!'), 10).handler;

   2. const d = debounce(() => console.log('!'), 10);
      OnSomething = d.handler;
      d.cancel();
 * */
export function debounce(handler, delay, shouldFire) {
    const result = {
        timer: undefined,
        handler: null,
        cancel: undefined,
    };
    result.cancel = () => {
        clearTimeout(result.timer);
        result.timer = undefined;
    };
    result.handler = ((...args) => {
        if (shouldFire?.() === false)
            return;
        if (result.timer !== undefined)
            clearTimeout(result.timer);
        result.timer = setTimeout(() => {
            result.timer = undefined;
            handler?.(...args);
        }, delay);
    });
    return result;
}
/** Кодируем строку в base64 */
export function base64Encode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
        return String.fromCharCode(parseInt('0x' + p1));
    }));
}
/**
  (почти) аналог c# nameof
  Как пользоваться:
 
  interface Person {
    firstName: string;
    lastName: string;
  }
  const personName1 = nameof<Person>("firstName"); // => "firstName"
  const personName2 = nameof<Person>("noName");    // => compile time error*
*/
export const nameof = (name) => name;
