//summary: Реализация murmur3 (Источник (сильно модифицированный): https://github.com/aggregateknowledge/js-murmur3-128)
//alias:   Murmur3
import { Long } from "../Types/Long";
function rotl(number, bits) {
    return (number.shiftLeft(bits)).or(number.shiftRightUnsigned(-bits));
}
var FMIX1 = new Long(0xED558CCD, 0xFF51AFD7), FMIX2 = new Long(0x1A85EC53, 0xC4CEB9FE);
function fmix(number) {
    number = number.xor(number.shiftRightUnsigned(33));
    number = number.multiply(FMIX1);
    number = number.xor(number.shiftRightUnsigned(33));
    number = number.multiply(FMIX2);
    number = number.xor(number.shiftRightUnsigned(33));
    return number;
}
var C1 = new Long(0x114253D5, 0x87C37B91), C2 = new Long(0x2745937F, 0x4CF5AD43);
function mixk1(k1) {
    k1 = k1.multiply(C1);
    k1 = rotl(k1, 31);
    return k1.multiply(C2);
}
function mixk2(k2) {
    k2 = k2.multiply(C2);
    k2 = rotl(k2, 33);
    return k2.multiply(C1);
}
// NOTE:  the chunk (or block) size is 16 bytes (for the 128bit Murmur3)
var CHUNK_SIZE_INTS = 4 /*ints = 16bytes*/;
var ZERO = Long.fromInt(0) /*for convenience*/, FIVE = Long.fromInt(5) /*for convenience*/;
var BMIX1 = Long.fromInt(0x52DCE729), BMIX2 = Long.fromInt(0x38495AB5);
export function hash(key, seed = 0) {
    key = key || new ArrayBuffer(0);
    seed = seed || 0;
    var byteBuffer = new Uint8Array(key);
    var chunkCount = byteBuffer.length >> 4 /*div by CHUNK_SIZE_BYTES*/;
    var chunkCountInInts = chunkCount << 2 /*4 ints per chunk*/;
    var lengthLong = Long.fromInt(byteBuffer.length);
    var seedLong = Long.fromInt(seed);
    var h1 = seedLong, h2 = seedLong;
    var k1, k2;
    var intBuffer = new Uint32Array(key, 0, chunkCountInInts);
    for (var i = 0; i < chunkCountInInts; i += CHUNK_SIZE_INTS) {
        k1 = new Long(intBuffer[i + 0], intBuffer[i + 1]);
        k2 = new Long(intBuffer[i + 2], intBuffer[i + 3]);
        h1 = h1.xor(mixk1(k1));
        h1 = rotl(h1, 27);
        h1 = h1.add(h2);
        h1 = h1.multiply(FIVE).add(BMIX1);
        h2 = h2.xor(mixk2(k2));
        h2 = rotl(h2, 31);
        h2 = h2.add(h1);
        h2 = h2.multiply(FIVE).add(BMIX2);
    }
    k1 = ZERO;
    k2 = ZERO;
    var byteRemainder = byteBuffer.length & 0x0F /*mask that is CHUNK_SIZE_BYTES - 1*/;
    var remainderBuffer = new Uint8Array(key, (chunkCount << 4 /*mult by CHUNK_SIZE_BYTES*/), byteRemainder) /*points to just remainder*/;
    if (byteRemainder >= 15)
        k2 = k2.xor((Long.fromInt(remainderBuffer[14])).shiftLeft(48));
    if (byteRemainder >= 14)
        k2 = k2.xor((Long.fromInt(remainderBuffer[13])).shiftLeft(40));
    if (byteRemainder >= 13)
        k2 = k2.xor((Long.fromInt(remainderBuffer[12])).shiftLeft(32));
    if (byteRemainder >= 12)
        k2 = k2.xor((Long.fromInt(remainderBuffer[11])).shiftLeft(24));
    if (byteRemainder >= 11)
        k2 = k2.xor((Long.fromInt(remainderBuffer[10])).shiftLeft(16));
    if (byteRemainder >= 10)
        k2 = k2.xor((Long.fromInt(remainderBuffer[9])).shiftLeft(8));
    if (byteRemainder >= 9)
        k2 = k2.xor(Long.fromInt(remainderBuffer[8]));
    if (byteRemainder >= 8) {
        var b = new Uint32Array(key, (chunkCount << 4 /*mult by CHUNK_SIZE_BYTES*/), 2) /*points to just remainder*/;
        k1 = k1.xor(new Long(b[0], b[1]));
    }
    else {
        if (byteRemainder >= 7)
            k1 = k1.xor((Long.fromInt(remainderBuffer[6])).shiftLeft(48));
        if (byteRemainder >= 6)
            k1 = k1.xor((Long.fromInt(remainderBuffer[5])).shiftLeft(40));
        if (byteRemainder >= 5)
            k1 = k1.xor((Long.fromInt(remainderBuffer[4])).shiftLeft(32));
        if (byteRemainder >= 4)
            k1 = k1.xor((Long.fromInt(remainderBuffer[3])).shiftLeft(24));
        if (byteRemainder >= 3)
            k1 = k1.xor((Long.fromInt(remainderBuffer[2])).shiftLeft(16));
        if (byteRemainder >= 2)
            k1 = k1.xor((Long.fromInt(remainderBuffer[1])).shiftLeft(8));
        if (byteRemainder >= 1)
            k1 = k1.xor((Long.fromInt(remainderBuffer[0])));
    }
    h1 = h1.xor(mixk1(k1));
    h2 = h2.xor(mixk2(k2));
    h1 = h1.xor(lengthLong);
    h2 = h2.xor(lengthLong);
    h1 = h1.add(h2);
    h2 = h2.add(h1);
    h1 = fmix(h1);
    h2 = fmix(h2);
    h1 = h1.add(h2);
    h2 = h2.add(h1);
    let result = new Uint32Array(4);
    result[0] = h1.getLowBitsUnsigned();
    result[1] = h1.getHighBitsUnsigned();
    result[2] = h2.getLowBitsUnsigned();
    result[3] = h2.getHighBitsUnsigned();
    return result.buffer;
}
;
