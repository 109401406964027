export function* Forward(target) {
    yield* target;
}
export function* Reverse(target) {
    for (let i = target.length - 1; i >= 0; i--)
        yield target[i];
}
export function* Select(target, transform) {
    let index = 0;
    for (let value of target)
        yield transform(value, index++);
}
export function* DefaultIfEmpty(target, defaultValue) {
    let iterator = target[Symbol.iterator]();
    let result = iterator.next();
    if (result.done)
        yield defaultValue;
    else
        yield* target;
}
export function* ChunkBy(target, keySelect, elementSelector, resultSelector) {
    let key, box, i = 0;
    for (let value of target) {
        let newKey = keySelect(value, i++);
        if (key !== newKey && box) {
            yield resultSelector(key, box);
            box = undefined;
        }
        if (!box)
            box = new Array();
        key = newKey;
        box.push(elementSelector(value));
    }
    if (box && 0 < box.length)
        yield resultSelector(key, box);
}
export function* Distinct(target, keySelector) {
    let set = new Set();
    for (let value of target) {
        let key = keySelector(value);
        if (set.has(key))
            continue;
        set.add(key);
        yield value;
    }
}
export function* DistinctFast(target) {
    let set = new Set();
    for (let value of target) {
        if (set.has(value))
            continue;
        set.add(value);
        yield value;
    }
}
export function* OfType(target, obj, typeName) {
    if (typeName) {
        for (let value of target) {
            if (typeName == typeof (value))
                yield value;
            else if (value instanceof obj)
                yield value;
        }
    }
    else {
        for (let value of target) {
            if (value instanceof obj)
                yield value;
        }
    }
}
export function* Where(target, predicate) {
    let index = 0;
    for (let value of target) {
        if (!predicate(value, index++))
            continue;
        yield value;
    }
}
export function* Skip(target, skip) {
    let index = 0;
    for (let value of target) {
        if (skip > index++)
            continue;
        yield value;
    }
}
export function* SkipWhile(target, predicate) {
    let index = 0, skipped = false;
    for (let value of target) {
        if (!skipped && !(skipped = !predicate(value, index++)))
            continue;
        yield value;
    }
}
export function* TakeWhile(target, predicate) {
    let index = 0;
    for (let value of target) {
        if (!predicate(value, index++))
            break;
        yield value;
    }
}
export function* Intersect(target, exceptions, condition, keySelect) {
    if (keySelect) {
        for (let value of target) {
            if (condition == exceptions.has(keySelect(value)))
                continue;
            yield value;
        }
    }
    else {
        for (let value of target) {
            if (condition == exceptions.has(value))
                continue;
            yield value;
        }
    }
}
export function* Repeat(value, count) {
    for (let i = 0; i < count; i++)
        yield value;
}
export function* Range(value, count) {
    let current = value;
    for (let i = 0; i < count; i++) {
        yield current;
        current++;
    }
}
export function* Union(first, second, keySelector) {
    let set = new Set();
    for (let value of first) {
        let key = keySelector(value);
        if (set.has(key))
            continue;
        set.add(key);
        yield value;
    }
    for (let value of second) {
        let key = keySelector(value);
        if (set.has(key))
            continue;
        set.add(key);
        yield value;
    }
}
export function* UnionFast(first, second) {
    let set = new Set();
    for (let value of first) {
        if (set.has(value))
            continue;
        set.add(value);
        yield value;
    }
    for (let value of second) {
        if (set.has(value))
            continue;
        set.add(value);
        yield value;
    }
}
export function* Join(target, oKeySelect, transform, map) {
    for (let value of target) {
        let key = oKeySelect(value);
        let innerSet = map.get(key);
        if ('undefined' === typeof innerSet)
            continue;
        for (let inner of innerSet)
            yield transform(value, inner);
    }
}
export function* GroupJoin(target, oKeySelect, transform, map) {
    for (let value of target) {
        let key = oKeySelect(value);
        let innerSet = map.get(key);
        if ('undefined' === typeof innerSet)
            continue;
        yield transform(value, innerSet);
    }
}
export function* GroupBy(map, resultSelect) {
    for (let key of map.keys())
        yield resultSelect(key, map.get(key));
}
export function* SelectMany(target, selector, transform) {
    let index = 0;
    for (let item of target) {
        for (let collectionItem of selector(item, index++))
            yield transform(item, collectionItem);
    }
}
export function* Concat(target, second) {
    yield* target;
    yield* second;
}
export function* Zip(first, second, transform, _index = 0) {
    let iteratorOne = first[Symbol.iterator]();
    let iteratorTwo = second[Symbol.iterator]();
    let retOne, retTwo;
    while (!(retOne = iteratorOne.next()).done && !(retTwo = iteratorTwo.next()).done) {
        yield transform(retOne.value, retTwo.value);
    }
}
