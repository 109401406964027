//alias: Ragtime.UnitSystem
// Файл сгенерирован автоматически. НЕ РЕДАКТИРОВАТЬ !
/** Роль формы */
export var FormRole;
(function (FormRole) {
    /** Форма общего вида */
    FormRole[FormRole["Generic"] = -1] = "Generic";
    /** Форма элемента */
    FormRole[FormRole["Item"] = 1] = "Item";
    /** Форма списка */
    FormRole[FormRole["List"] = 2] = "List";
    /** Форма поиска */
    FormRole[FormRole["Lookup"] = 3] = "Lookup";
    /** Форма папки */
    FormRole[FormRole["Folder"] = 4] = "Folder";
    /** Форма записи */
    FormRole[FormRole["Record"] = 5] = "Record";
})(FormRole || (FormRole = {}));
/** Время жизни экземпляра сервиса */
export var ServiceLifetime;
(function (ServiceLifetime) {
    /** Один экземпляр на все время жизни приложения */
    ServiceLifetime[ServiceLifetime["Singleton"] = 0] = "Singleton";
    /** Каждый раз создавать новый экземпляр */
    ServiceLifetime[ServiceLifetime["Transient"] = 1] = "Transient";
})(ServiceLifetime || (ServiceLifetime = {}));
