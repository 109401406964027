//summary: Ошибки
//alias:   Ragtime.Error
/** Ошибка уровня пользователя. Подразумевается, что содержит внятное сообщение */
export class Application extends Error {
    constructor(message) {
        super(message);
    }
    /** Возбуждаем исключение */
    static throw(message) {
        throw new Application(message);
    }
    /** Возбуждаем исключение */
    static throwIf(condition, message) {
        if (condition)
            throw new Application(message);
    }
}
/** Внуренняя ошибка. Сообщение пользователю бесполезно, поэтому может быть любым.  */
export class Operation extends Error {
    constructor(code, message) {
        if (!message)
            message = "Internal error";
        super(`[${code}] ${message}`);
    }
    /** Возбуждаем исключение, если условие выполнено.  */
    static throwIf(condition, code, message) {
        if (condition)
            throw new Operation(code, message);
    }
    /** Возбуждаем исключение, если условие не выполнено.  */
    static throwIfNot(condition, code, message) {
        if (!condition)
            throw new Operation(code, message);
    }
    /** Возбуждаем исключение */
    static throw(code, message) {
        throw new Operation(code, message);
    }
    /** Записываем в консоль информацию об ошибке, однако исключение не возбуждаем */
    static log(code, message, ...extra) {
        console.error(`${code}: ${message}`, ...extra);
    }
}
/** Что-то еще не реализовано.  */
export class NotImplemented extends Error {
    constructor(message) {
        if (!message)
            message = "Not implemented";
        super(message);
    }
    static throw() {
        throw new NotImplemented();
    }
}
/** Проверяем условие, если что не так - исключение. */
export function assertIsNotNullOrUndefined(...values) {
    for (let value of values || [])
        failIf(value === null || value === undefined, "DEF3488D9E41");
}
/** Проверяем условие, если что не так - исключение. */
export function assertIs(condition) {
    failIf(!condition, "0C16F2C32645");
}
/** Проверяем условие, если что не так - исключение. */
export function failIf(condition, errorCode, message) {
    if (condition) {
        if (!message)
            message = "Assertion failed: isDefined";
        debugger;
        throw new Operation(errorCode, message);
    }
}
/** Выводим в консоль предупреждение. */
export function warning(code, message) {
    console.warn(code, message);
}
