//alias:   Ragtime.Observables
export function asyncComputed(evaluate, defaultValue) {
    const value = ko.observable(defaultValue);
    let cancel;
    let settingValue;
    return ko.pureComputed(() => {
        value(); // регистрация зависимости
        if (!settingValue) {
            // Обрабатываем ситуацию, когда запросили значение, а предыдущее значение еще не вычислено
            cancel?.();
            cancel = undefined;
            const evaluated = Promise.resolve(evaluate());
            new Promise((resolve, reject) => {
                cancel = reject;
                evaluated.then(newValue => resolve(newValue));
            }).then((newValue) => {
                cancel = undefined;
                settingValue = true;
                value(newValue);
                settingValue = false;
            });
        }
        return value();
    });
}
export const observable = ko.observable;
export const isObservable = ko.isObservable;
export const isWriteableObservable = ko.isWriteableObservable;
export const computed = ko.computed;
export const pureComputed = ko.pureComputed;
export const isComputed = ko.isComputed;
export const subscribable = ko.subscribable;
export const isSubscribable = ko.isSubscribable;
