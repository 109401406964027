export const CONST_INVALID_KEY = "Key selector returned undefined Key";
export const CONST_NO_STRING = "Type must be a string.";
export const CONST_DUPLICATE = "Object already has property [key]";
export const CONST_NOTHING_FOUND = "No element satisfies the condition in predicate";
export const CONST_NO_ELEMENTS = "The source sequence is empty.";
export const CONST_TOO_MANY = "More than one element satisfies the condition in predicate.";
export const CONST_OUTOFRANGE = "Argument Out Of Range";
export const CONST_KEY = "key";
export const CONST_UNDEFINED = "undefined";
export const CONST_LENGTH = "length";
export const CONST_FUNCTION = "function";
export const CONST_BOOLEAN = "boolean";
export const CONST_NUMBER = "number";
export const CONST_OBJECT = "object";
export const CONST_STRING = "string";
export const CONST_SYMBOL = "symbol";
export const CONST_EMPTY_STRING = "";
/** Default predicate, always true */
export var trueFn = () => true;
/** Default transformer, returns self */
export var selfFn = (o) => o;
/** Default Grouping */
export var defGrouping = (a, b) => {
    if (!b[CONST_KEY]) {
        b[CONST_KEY] = a;
    }
    return b;
};
/** Returns default value for the type */
export function getDefaultVal(type, value = undefined) {
    if (typeof type !== CONST_STRING)
        throw new TypeError(CONST_NO_STRING);
    // Handle simple types (primitives and plain function/object)
    switch (type) {
        case CONST_BOOLEAN: return false;
        case CONST_NUMBER: return 0;
        case CONST_OBJECT: return null === value ? null : undefined;
        case CONST_STRING: return CONST_EMPTY_STRING;
        case CONST_SYMBOL: return Symbol();
    }
    return undefined;
}
/** Returns a map of element bsed on extracted keys  **/
export function getKeyedMap(iterable, keySelector, selElement) {
    let map = new Map();
    for (let value of iterable) {
        let key = keySelector(value);
        if (!key)
            throw CONST_INVALID_KEY;
        let group = map.get(key);
        if (!group) {
            group = [];
            map.set(key, group);
        }
        group.push(selElement(value));
    }
    return map;
}
export function getKeyedMapFast(iterable, keySelector) {
    let map = new Map();
    for (let value of iterable) {
        let key = keySelector(value);
        if (!key)
            throw CONST_INVALID_KEY;
        let group = map.get(key);
        if (!group) {
            group = [];
            map.set(key, group);
        }
        group.push(value);
    }
    return map;
}
export function getKeys(iterable, keySelector) {
    let set = new Set();
    if (keySelector) {
        for (let value of iterable) {
            set.add(keySelector(value));
        }
    }
    else {
        for (let value of iterable) {
            set.add(value);
        }
    }
    return set;
}
