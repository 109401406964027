//summary: Реализация длинного целого (Источник: https://code.google.com/p/closure-library/source/browse/closure/goog/math/long.js)
//alias:   Ragtime.Long
//bundle:  true
export class Long {
    constructor(low, high) {
        this.low_ = low | 0;
        this.high_ = high | 0;
    }
    static fromInt(intValue) {
        intValue = intValue | 0;
        if (-128 <= intValue && intValue < 128) {
            let index = intValue + 128;
            let result = Long._cache[index];
            if (result === undefined) {
                result = new Long(intValue, intValue < 0 ? -1 : 0);
                Long._cache[index] = result;
            }
            return result;
        }
        else
            return new Long(intValue, intValue < 0 ? -1 : 0);
    }
    static { this._cache = new Array(256); }
    static fromNumber(value) {
        if (isNaN(value))
            return Long.getZero();
        else if (value <= -Long.TWO_PWR_63_DBL_)
            return Long.getMinValue();
        else if (value + 1 >= Long.TWO_PWR_63_DBL_)
            return Long.getMaxValue();
        else if (value < 0)
            return Long.fromNumber(-value).negate();
        else
            return new Long((value % Long.TWO_PWR_32_DBL_) | 0, (value / Long.TWO_PWR_32_DBL_) | 0);
    }
    ;
    static fromBits(lowBits, highBits) {
        return new Long(lowBits, highBits);
    }
    static getZero() {
        return Long.fromInt(0);
    }
    ;
    static { this.getOne = function () {
        return Long.fromInt(1);
    }; }
    static getTwoPwr24() {
        if (Long.TWO_PWR_24_ === undefined)
            Long.TWO_PWR_24_ = Long.fromInt(1 << 24);
        return Long.TWO_PWR_24_;
    }
    ;
    static { this.TWO_PWR_32_DBL_ = (1 << 16) * (1 << 16); }
    static { this.TWO_PWR_63_DBL_ = (1 << 16) * (1 << 16) * (1 << 16) * (1 << 16) / 2; }
    static getMinValue() {
        if (Long._MinValue === undefined)
            Long._MinValue = Long.fromBits(0, 0x80000000 | 0);
        return Long._MinValue;
    }
    static getMaxValue() {
        if (Long._MaxValue === undefined)
            Long._MaxValue = Long.fromBits(0xFFFFFFFF | 0, 0x7FFFFFFF | 0);
        return Long._MaxValue;
    }
    ;
    toNumber() {
        return this.high_ * Long.TWO_PWR_32_DBL_ + this.getLowBitsUnsigned();
    }
    ;
    getLowBitsUnsigned() {
        return (this.low_ >= 0) ? this.low_ : Long.TWO_PWR_32_DBL_ + this.low_;
    }
    ;
    getHighBitsUnsigned() {
        return (this.high_ >= 0) ? this.high_ : Long.TWO_PWR_32_DBL_ + this.high_;
    }
    ;
    isZero() {
        return this.high_ == 0 && this.low_ == 0;
    }
    ;
    isOdd() {
        return (this.low_ & 1) == 1;
    }
    ;
    isNegative() {
        return this.high_ < 0;
    }
    ;
    equals(other) {
        return (this.high_ == other.high_) && (this.low_ == other.low_);
    }
    ;
    compare(other) {
        if (this.equals(other)) {
            return 0;
        }
        var thisNeg = this.isNegative();
        var otherNeg = other.isNegative();
        if (thisNeg && !otherNeg)
            return -1;
        if (!thisNeg && otherNeg)
            return 1;
        // at this point, the signs are the same, so subtraction will not overflow
        if (this.subtract(other).isNegative())
            return -1;
        else
            return 1;
    }
    ;
    lessThan(other) {
        return this.compare(other) < 0;
    }
    ;
    shiftLeft(numBits) {
        numBits &= 63;
        if (numBits == 0) {
            return this;
        }
        else {
            var low = this.low_;
            if (numBits < 32) {
                var high = this.high_;
                return Long.fromBits(low << numBits, (high << numBits) | (low >>> (32 - numBits)));
            }
            else {
                return Long.fromBits(0, low << (numBits - 32));
            }
        }
    }
    shiftRightUnsigned(numBits) {
        numBits &= 63;
        if (numBits == 0)
            return this;
        else {
            var high = this.high_;
            if (numBits < 32) {
                var low = this.low_;
                return Long.fromBits((low >>> numBits) | (high << (32 - numBits)), high >>> numBits);
            }
            else if (numBits == 32)
                return Long.fromBits(high, 0);
            else
                return Long.fromBits(high >>> (numBits - 32), 0);
        }
    }
    or(other) {
        return Long.fromBits(this.low_ | other.low_, this.high_ | other.high_);
    }
    xor(other) {
        return Long.fromBits(this.low_ ^ other.low_, this.high_ ^ other.high_);
    }
    not() {
        return Long.fromBits(~this.low_, ~this.high_);
    }
    ;
    negate() {
        if (this.equals(Long.getMinValue()))
            return Long.getMinValue();
        else
            return this.not().add(Long.getOne());
    }
    ;
    subtract(other) {
        return this.add(other.negate());
    }
    ;
    multiply(other) {
        if (this.isZero())
            return Long.getZero();
        else if (other.isZero())
            return Long.getZero();
        if (this.equals(Long.getMinValue()))
            return other.isOdd() ? Long.getMinValue() : Long.getZero();
        else if (other.equals(Long.getMinValue()))
            return this.isOdd() ? Long.getMinValue() : Long.getZero();
        if (this.isNegative()) {
            if (other.isNegative()) {
                return this.negate().multiply(other.negate());
            }
            else {
                return this.negate().multiply(other).negate();
            }
        }
        else if (other.isNegative()) {
            return this.multiply(other.negate()).negate();
        }
        // If both longs are small, use float multiplication
        if (this.lessThan(Long.getTwoPwr24()) && other.lessThan(Long.getTwoPwr24())) {
            return Long.fromNumber(this.toNumber() * other.toNumber());
        }
        // Divide each long into 4 chunks of 16 bits, and then add up 4x4 products.
        // We can skip products that would overflow.
        var a48 = this.high_ >>> 16;
        var a32 = this.high_ & 0xFFFF;
        var a16 = this.low_ >>> 16;
        var a00 = this.low_ & 0xFFFF;
        var b48 = other.high_ >>> 16;
        var b32 = other.high_ & 0xFFFF;
        var b16 = other.low_ >>> 16;
        var b00 = other.low_ & 0xFFFF;
        var c48 = 0, c32 = 0, c16 = 0, c00 = 0;
        c00 += a00 * b00;
        c16 += c00 >>> 16;
        c00 &= 0xFFFF;
        c16 += a16 * b00;
        c32 += c16 >>> 16;
        c16 &= 0xFFFF;
        c16 += a00 * b16;
        c32 += c16 >>> 16;
        c16 &= 0xFFFF;
        c32 += a32 * b00;
        c48 += c32 >>> 16;
        c32 &= 0xFFFF;
        c32 += a16 * b16;
        c48 += c32 >>> 16;
        c32 &= 0xFFFF;
        c32 += a00 * b32;
        c48 += c32 >>> 16;
        c32 &= 0xFFFF;
        c48 += a48 * b00 + a32 * b16 + a16 * b32 + a00 * b48;
        c48 &= 0xFFFF;
        return Long.fromBits((c16 << 16) | c00, (c48 << 16) | c32);
    }
    add(other) {
        // Divide each number into 4 chunks of 16 bits, and then sum the chunks.
        var a48 = this.high_ >>> 16;
        var a32 = this.high_ & 0xFFFF;
        var a16 = this.low_ >>> 16;
        var a00 = this.low_ & 0xFFFF;
        var b48 = other.high_ >>> 16;
        var b32 = other.high_ & 0xFFFF;
        var b16 = other.low_ >>> 16;
        var b00 = other.low_ & 0xFFFF;
        var c48 = 0, c32 = 0, c16 = 0, c00 = 0;
        c00 += a00 + b00;
        c16 += c00 >>> 16;
        c00 &= 0xFFFF;
        c16 += a16 + b16;
        c32 += c16 >>> 16;
        c16 &= 0xFFFF;
        c32 += a32 + b32;
        c48 += c32 >>> 16;
        c32 &= 0xFFFF;
        c48 += a48 + b48;
        c48 &= 0xFFFF;
        return Long.fromBits((c16 << 16) | c00, (c48 << 16) | c32);
    }
}
