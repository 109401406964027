import './Ragtime.Core2.css';
{
  const href='/Ragtime.Core2/Ragtime.Core2.css?' + window.ragtime.version;
  document.getElementsByTagName("head")[0].insertAdjacentHTML("beforeend", "<link rel='stylesheet' href='" + href + "' />")
}

export * as Src_DataService_DataService from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/DataService/DataService';
export * as Src_Errors_Error from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Errors/Error';
export * as Src_Metadata_Core_TableLike_ITableLike from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Metadata/Core/TableLike/ITableLike';
export * as Src_Metadata_Core_TypeInfo_TypeInfo from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Metadata/Core/TypeInfo/TypeInfo';
export * as Src_Metadata_Services_DataService from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Metadata/Services/DataService';
export * as Src_Runtime_Registry from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Runtime/Registry';
export * as Src_Runtime_ServiceProvider from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Runtime/ServiceProvider';
export * as Src_Signal_Signal from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Signal/Signal';
export * as Src_Types_Date from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Types/Date';
export * as Src_Types_Long from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Types/Long';
export * as Src_Types_TimeSpan from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Types/TimeSpan';
export * as Src_Types_Types from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Types/Types';
export * as Src_UnitSystem_Manifest from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/UnitSystem/Manifest';
export * as Src_Utils_AsyncCollection from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/AsyncCollection';
export * as Src_Utils_Callbacks from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Callbacks';
export * as Src_Utils_Format from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Format';
export * as Src_Utils_Linq_Ts_linq from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Linq.Ts/linq';
export * as Src_Utils_Misc from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Misc';
export * as Src_Utils_Murmur3 from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Murmur3';
export * as Src_Utils_Observables from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Observables';
export * as _Ragtime_Ragtime_Metadata_DataSource_Ragtime_Metadata_DataSource_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.DataSource/Ragtime.Metadata.DataSource.generated';
export * as _Ragtime_Ragtime_Metadata_Documentation_Ragtime_Metadata_Documentation_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Documentation/Ragtime.Metadata.Documentation.generated';
export * as _Ragtime_Ragtime_Metadata_FieldUi_Ragtime_Metadata_FieldUi_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldUi/Ragtime.Metadata.FieldUi.generated';
export * as _Ragtime_Ragtime_Metadata_FieldBase_Ragtime_Metadata_FieldBase_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldBase/Ragtime.Metadata.FieldBase.generated';
export * as _Ragtime_Ragtime_Metadata_FieldGroup_Ragtime_Metadata_FieldGroup_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldGroup/Ragtime.Metadata.FieldGroup.generated';
export * as _Ragtime_Ragtime_Metadata_FieldNames_Ragtime_Metadata_FieldNames_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldNames/Ragtime.Metadata.FieldNames.generated';
export * as _Ragtime_Ragtime_Metadata_FieldRules_Ragtime_Metadata_FieldRules_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldRules/Ragtime.Metadata.FieldRules.generated';
export * as _Ragtime_Ragtime_Metadata_FieldStorage_Ragtime_Metadata_FieldStorage_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldStorage/Ragtime.Metadata.FieldStorage.generated';
export * as _Ragtime_Ragtime_Metadata_Field_Ragtime_Metadata_Field_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Field/Ragtime.Metadata.Field.generated';
export * as _Ragtime_Ragtime_Metadata_Index_Ragtime_Metadata_Index_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Index/Ragtime.Metadata.Index.generated';
export * as _Ragtime_Ragtime_Metadata_KeyItem_Ragtime_Metadata_KeyItem_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.KeyItem/Ragtime.Metadata.KeyItem.generated';
export * as _Ragtime_Ragtime_Metadata_Link_Ragtime_Metadata_Link_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Link/Ragtime.Metadata.Link.generated';
export * as _Ragtime_Ragtime_Metadata_MetadataUi_Ragtime_Metadata_MetadataUi_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.MetadataUi/Ragtime.Metadata.MetadataUi.generated';
export * as _Ragtime_Ragtime_Metadata_Numerator_Ragtime_Metadata_Numerator_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Numerator/Ragtime.Metadata.Numerator.generated';
export * as _Ragtime_Ragtime_Metadata_Parameter_Ragtime_Metadata_Parameter_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Parameter/Ragtime.Metadata.Parameter.generated';
export * as _Ragtime_Ragtime_Metadata_RefTo_Ragtime_Metadata_RefTo_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.RefTo/Ragtime.Metadata.RefTo.generated';
export * as _Ragtime_Ragtime_Metadata_Security_Ragtime_Metadata_Security_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Security/Ragtime.Metadata.Security.generated';
export * as _Ragtime_Ragtime_Metadata_TablePartUi_Ragtime_Metadata_TablePartUi_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.TablePartUi/Ragtime.Metadata.TablePartUi.generated';
export * as _Ragtime_Ragtime_Metadata_TablePart_Ragtime_Metadata_TablePart_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.TablePart/Ragtime.Metadata.TablePart.generated';
export * as _Ragtime_Ragtime_Metadata_TableStorage_Ragtime_Metadata_TableStorage_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.TableStorage/Ragtime.Metadata.TableStorage.generated';
export * as _Ragtime_Ragtime_Metadata_WebClient_Ragtime_Metadata_WebClient_generated from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.WebClient/Ragtime.Metadata.WebClient.generated';

export * as Ragtime_DataService from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/DataService/DataService';
export * as Ragtime_Error from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Errors/Error';
export * as Ragtime_Metadata_TableLike from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Metadata/Core/TableLike/ITableLike';
export * as Ragtime_Metadata_TypeInfo from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Metadata/Core/TypeInfo/TypeInfo';
export * as Ragtime_Metadata_DataService from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Metadata/Services/DataService';
export * as Ragtime_Registry from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Runtime/Registry';
export * as Ragtime_ServiceProvider from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Runtime/ServiceProvider';
export * as Ragtime_Signal from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Signal/Signal';
export * as Ragtime_Date from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Types/Date';
export * as Ragtime_Long from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Types/Long';
export * as Ragtime_TimeSpan from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Types/TimeSpan';
export * as Ragtime_Types from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Types/Types';
export * as Ragtime_UnitSystem from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/UnitSystem/Manifest';
export * as Ragtime_AsyncCollection from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/AsyncCollection';
export * as Ragtime_Callbacks from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Callbacks';
export * as Ragtime_Format from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Format';
export * as linq from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Linq.Ts/linq';
export * as Ragtime_Misc from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Misc';
export * as Murmur3 from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Murmur3';
export * as Ragtime_Observables from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/Src/Utils/Observables';
export * as Ragtime_Metadata_DataSource from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.DataSource/Ragtime.Metadata.DataSource.generated';
export * as Ragtime_Metadata_Documentation from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Documentation/Ragtime.Metadata.Documentation.generated';
export * as Ragtime_Metadata_FieldUi from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldUi/Ragtime.Metadata.FieldUi.generated';
export * as Ragtime_Metadata_FieldBase from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldBase/Ragtime.Metadata.FieldBase.generated';
export * as Ragtime_Metadata_FieldGroup from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldGroup/Ragtime.Metadata.FieldGroup.generated';
export * as Ragtime_Metadata_FieldNames from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldNames/Ragtime.Metadata.FieldNames.generated';
export * as Ragtime_Metadata_FieldRules from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldRules/Ragtime.Metadata.FieldRules.generated';
export * as Ragtime_Metadata_FieldStorage from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.FieldStorage/Ragtime.Metadata.FieldStorage.generated';
export * as Ragtime_Metadata_Field from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Field/Ragtime.Metadata.Field.generated';
export * as Ragtime_Metadata_Index from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Index/Ragtime.Metadata.Index.generated';
export * as Ragtime_Metadata_KeyItem from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.KeyItem/Ragtime.Metadata.KeyItem.generated';
export * as Ragtime_Metadata_Link from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Link/Ragtime.Metadata.Link.generated';
export * as Ragtime_Metadata_MetadataUi from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.MetadataUi/Ragtime.Metadata.MetadataUi.generated';
export * as Ragtime_Metadata_Numerator from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Numerator/Ragtime.Metadata.Numerator.generated';
export * as Ragtime_Metadata_Parameter from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Parameter/Ragtime.Metadata.Parameter.generated';
export * as Ragtime_Metadata_RefTo from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.RefTo/Ragtime.Metadata.RefTo.generated';
export * as Ragtime_Metadata_Security from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.Security/Ragtime.Metadata.Security.generated';
export * as Ragtime_Metadata_TablePartUi from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.TablePartUi/Ragtime.Metadata.TablePartUi.generated';
export * as Ragtime_Metadata_TablePart from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.TablePart/Ragtime.Metadata.TablePart.generated';
export * as Ragtime_Metadata_TableStorage from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.TableStorage/Ragtime.Metadata.TableStorage.generated';
export * as Ragtime_Metadata_WebClient from 'D:/Git/Ragtime/Cactus.Master/Main/Core/Ragtime.Core2/_Ragtime/Ragtime.Metadata.WebClient/Ragtime.Metadata.WebClient.generated';

