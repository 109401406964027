//_@ts-nocheck
// Этот файл сгенерирован автоматически. Его НЕЛЬЗЯ РЕДАКТИРОВАТЬ.
//generated:true
//alias:Ragtime.Metadata.FieldRules
export var FieldNoValueAction;
(function (FieldNoValueAction) {
    FieldNoValueAction[FieldNoValueAction["None"] = 0] = "None";
    FieldNoValueAction[FieldNoValueAction["Error"] = 1] = "Error";
    FieldNoValueAction[FieldNoValueAction["ErrorIfNotDraft"] = 2] = "ErrorIfNotDraft";
    FieldNoValueAction[FieldNoValueAction["Auto"] = 3] = "Auto";
})(FieldNoValueAction || (FieldNoValueAction = {}));
export var FieldTooLongAction;
(function (FieldTooLongAction) {
    FieldTooLongAction[FieldTooLongAction["Error"] = 0] = "Error";
    FieldTooLongAction[FieldTooLongAction["Truncate"] = 1] = "Truncate";
})(FieldTooLongAction || (FieldTooLongAction = {}));
export var DateGranularity;
(function (DateGranularity) {
    DateGranularity[DateGranularity["Max"] = 0] = "Max";
    DateGranularity[DateGranularity["Year"] = 1] = "Year";
    DateGranularity[DateGranularity["Quarter"] = 2] = "Quarter";
    DateGranularity[DateGranularity["Month"] = 3] = "Month";
    DateGranularity[DateGranularity["Day"] = 5] = "Day";
    DateGranularity[DateGranularity["Hour"] = 6] = "Hour";
    DateGranularity[DateGranularity["Minute"] = 7] = "Minute";
    DateGranularity[DateGranularity["Second"] = 8] = "Second";
})(DateGranularity || (DateGranularity = {}));
