//_@ts-nocheck
// Этот файл сгенерирован автоматически. Его НЕЛЬЗЯ РЕДАКТИРОВАТЬ.
//generated:true
//alias:Ragtime.Metadata.FieldUi
export var FieldHide;
(function (FieldHide) {
    FieldHide[FieldHide["false"] = 0] = "false";
    FieldHide[FieldHide["Form"] = 1] = "Form";
    FieldHide[FieldHide["List"] = 2] = "List";
    FieldHide[FieldHide["true"] = 3] = "true";
    FieldHide[FieldHide["WhenGroupOpen"] = 4] = "WhenGroupOpen";
    FieldHide[FieldHide["WhenGroupClosed"] = 5] = "WhenGroupClosed";
})(FieldHide || (FieldHide = {}));
export var Format;
(function (Format) {
    Format[Format["None"] = 0] = "None";
    Format[Format["currency"] = 1] = "currency";
    Format[Format["fixedPoint"] = 2] = "fixedPoint";
    Format[Format["year"] = 3] = "year";
    Format[Format["shortDate"] = 4] = "shortDate";
    Format[Format["shortDateShortTime"] = 5] = "shortDateShortTime";
    Format[Format["shortDateTime"] = 6] = "shortDateTime";
    Format[Format["timeHMmSsMs"] = 7] = "timeHMmSsMs";
    Format[Format["timeHMmSs"] = 8] = "timeHMmSs";
    Format[Format["timeHMm"] = 9] = "timeHMm";
    Format[Format["timeMmSs"] = 10] = "timeMmSs";
    Format[Format["timeMmSsMs"] = 11] = "timeMmSsMs";
    Format[Format["boolean"] = 12] = "boolean";
    Format[Format["integer"] = 13] = "integer";
})(Format || (Format = {}));
