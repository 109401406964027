//alias: Ragtime.Signal
import * as DataService from "Ragtime.DataService";
import * as Murmur from "Murmur3";
import * as Error from "Ragtime.Error";
/** Подписываемся на сигнал */
export function subscribe(key, handler) {
    key = key || "";
    let ss = subscriptions.get(key);
    if (!ss) {
        ss = new Set();
        subscriptions.set(key, ss);
    }
    const result = {
        key: key,
        handler: handler,
        dispose: null,
    };
    result.dispose = () => dispose(result);
    ss.add(result);
    return result;
}
/** Запускаем сигнал */
export async function fire(key, data) {
    for (let subscription of subscriptions.get(key) || []) {
        try {
            if (subscription.handler)
                await Promise.resolve(subscription.handler(data));
        }
        catch (e) {
        }
    }
}
/** Удаляем подписку */
function dispose(subscription) {
    if (!subscription)
        return;
    const ss = subscriptions.get(subscription.key);
    if (ss)
        ss.delete(subscription);
    if (ss.size === 0)
        subscriptions.delete(subscription.key);
}
/** Ключ: ключ сигнала, значение: Id подписок */
const subscriptions = new Map();
export function initialize() {
    // При каждом вызове сервера посылаем свои подписки и получаем сигналы со стороны сервера
    DataService.addBeforeRequest(async () => {
        // Вызовы, оформленные при помощи postponeCall, идут до основного вызова. Мы вызываем Subscribe для того, чтобы сигналы могли оформить подписку до того, как основной call возбудит событие
        DataService.postponeCall("Ragtime.Signal", "Subscribe", { keys: Array.from(subscriptions.keys()) }, false, { background: true });
        // Дополнительные вызовы, оформленные при помощи call, идут после основного вызова. Поэтому переменная signals будет содержать данные возбужденных событий
        let signals = await DataService.call("Ragtime.Signal", "Sync", { keys: Array.from(subscriptions.keys()) }, { background: true });
        for (let signal of signals || [])
            await fire(signal.key, signal.data);
    });
}
/** Построитель ключей. Критически важно, чтобы ключи формировались так же, как и в c# */
export class KeyBuilder {
    constructor(lenInBytes) {
        this.buffer = new ArrayBuffer(lenInBytes);
        this.digest = new Int16Array(this.buffer);
        this.wordOffset = 0;
    }
    /** Добавляем строку, которая является Guid-ом */
    addGuid(value) {
        value = value || "00000000-0000-0000-0000-000000000000";
        Error.Operation.throwIf(value.length !== 36, "383496F39C0F", "Bad Guid");
        return this.addString(value, 36);
    }
    /** Добавляем строку */
    addString(value, len) {
        value = (' '.repeat(len) + (value || "")).slice(-len);
        for (let i = 0; i < value.length; i++)
            this.digest[i + this.wordOffset] = value.charCodeAt(i);
        this.wordOffset += value.length;
        return this;
    }
    /** Добавляем короткое целое */
    addInt16(value) {
        this.digest[this.wordOffset] = value;
        this.wordOffset += 1;
        return this;
    }
    /** Форматируем один байт */
    format(value) {
        let result = value.toString(16).toUpperCase();
        return result.length === 2 ? result : "0" + result;
    }
    /** Возвращаем готовый ключ */
    build() {
        Error.Operation.throwIf(this.wordOffset !== this.digest.length, "0B644766B95F", "Oversize or undersize");
        return Array.from(new Uint8Array(Murmur.hash(this.buffer))).map(this.format).join("");
    }
}
