import { loadClass } from "Ragtime.Misc";
/** Функция-декоратор. Этим декоратором помечается класс, который потом можно будет получить вызовом get() */
export function Tag(tag) {
    return (classConstructor) => { };
}
export function register(cls) {
    cls.tag = cls.tag.toLowerCase();
    let list = data[cls.tag];
    if (!list)
        list = data[cls.tag] = [];
    list.push(cls);
}
/** Возвращаем зарегистрированные классы, или пустой массив */
export function get(tag) {
    return data[tag.toLowerCase()] || [];
}
/** Возвращаем первый конструктор для указанного тега, или null. */
export async function getConstructor(tag) {
    const info = get(tag)[0];
    if (!info)
        return null;
    return await loadClass(info.moduleName, info.className);
}
/** Создаем объект, используя первый встретившийся зарегистрированный класс для указанного тега, или null. Предполагаем, что конструктор не принимает параметров */
export async function createInstance(tag) {
    const constructor = await getConstructor(tag);
    if (!constructor)
        return null;
    return new constructor();
}
/** Создаем объекты, используя зарегистрированные классы для указанного тега, или пустой массив. Предполагаем, что конструктор не принимает параметров */
export async function createInstances(tag) {
    const classes = get(tag);
    const constructors = await Promise.all(classes.map(_ => loadClass(_.moduleName, _.className)));
    return constructors.map(_ => new _());
}
var data = {};
