//alias: Ragtime.ServiceProvider
import * as Error from "Ragtime.Error";
import { loadClass } from "Ragtime.Misc";
import { ServiceLifetime } from "Ragtime.UnitSystem";
export { ServiceLifetime };
/** Функция-декоратор. Этим декоратором помечается класс, который потом можно будет получить вызовом getService(). Предназначен для анализа компилитором*/
export function Service(id, lifetime) {
    return (classConstructor) => { };
}
/** Возвращаем экземпляр службы */
export async function getService(serviceId, throwException = true) {
    let result = singletons.get(serviceId);
    if (result)
        return result;
    const info = registry.get(serviceId);
    if (!info) {
        Error.Operation.throwIf(throwException, "B4B4F6E9DB05", `Service not found: ${serviceId}`);
        return undefined;
    }
    result = await Promise.resolve(info.factory());
    if (info.lifetime == ServiceLifetime.Singleton)
        singletons.set(serviceId, result);
    return result;
}
/** Регистрируем класс как сервис */
export function registerClassAsService(serviceId, moduleName, className, lifetime) {
    registry.set(serviceId, { lifetime, factory: () => loadClass(moduleName, className) });
    singletons.delete(serviceId);
}
/** Регистрируем произвольное значение как сервис. Lifetime всегда будет singleton */
export function registerValueAsService(serviceId, value) {
    registry.set(serviceId, { lifetime: ServiceLifetime.Singleton, factory: () => value });
    singletons.set(serviceId, value);
}
let registry = new Map();
let singletons = new Map();
