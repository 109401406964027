//summary: Тип TimeSpan
//alias:   Ragtime.TimeSpan
import * as Format from "../Utils/Format";
import * as Error from "Ragtime.Error";
export class TimeSpan {
    constructor(value) {
        this._totalMs = 0;
        this._totalMs = value || 0;
    }
    static create(h, m, s, ms, sign = 1) {
        let value = ms || 0;
        value += (s || 0) * 1000;
        value += (m || 0) * 60 * 1000;
        value += (h || 0) * 60 * 60 * 1000;
        return new TimeSpan(value * (sign > 0 ? 1 : -1));
    }
    /** Значение отрицательно? */
    get negative() {
        return this._totalMs < 0;
    }
    /** Возвращаем полное целых часов. Всегда положительно */
    getTotalHours(round = true) {
        return this.transform(this.getTotalMinutes(false) / 60.0, round, true);
    }
    /** Возвращаем минуты. Всегда положительно */
    getMinutes(round = true) {
        return this.transform(this.getTotalMinutes(false) % 60, round, true);
    }
    /** Возвращаем полное число минут. Всегда положительно */
    getTotalMinutes(round = true) {
        return this.transform(this.getTotalSeconds(false) / 60.0, round, true);
    }
    /** Возвращаем секунды. Всегда положительно */
    getSeconds(round = true) {
        return this.transform(this.getTotalSeconds(false) % 60, round, true);
    }
    /** Возвращаем полное число секунд. Всегда положительно */
    getTotalSeconds(round = true) {
        return this.transform(this._totalMs / 1000.0, round, true);
    }
    /** Возвращаем миллисекунды. Всегда положительно */
    getMilliseconds(round = true) {
        return this.transform(this._totalMs % 1000, round, true);
    }
    /** Возвращаем полное число миллисекунд. Всегда положительно */
    getTotalMilliseconds(round = true) {
        return this.transform(this._totalMs, round, true);
    }
    transform(value, round, abs) {
        let result = value;
        if (round) {
            result = value < 0
                ? Math.ceil(value)
                : Math.floor(value);
        }
        if (abs)
            result = Math.abs(result);
        return result;
    }
    /** Возвращаем число миллисекунд. Со своим знаком */
    getValue() {
        return this._totalMs;
    }
    /** Конструируем объект из строки, или null */
    static parse(value, format) {
        if (!value)
            return null;
        if (!Format.isTime(format))
            format = "timeHMmSs";
        let sign = 1;
        let parts = [];
        let partPointer = 0;
        function putChar(c) {
            while (parts.length <= partPointer)
                parts.push("");
            parts[partPointer] += c;
        }
        for (let c of value) {
            if (c >= '0' && c <= '9')
                putChar(c);
            else {
                if (c == "-" && parts.length == 0)
                    sign = -1;
                else if (c === '.' || c === ',')
                    putChar(".");
                else
                    partPointer += 1;
            }
        }
        // Ищем функцию-парсер среди статических методов класса TimeSpan.
        let parser;
        parser = TimeSpan["parse" + format.substring("time".length)];
        Error.Operation.throwIf(!parser, "EC8585807562", "TimeSpan: Parser not found");
        return parser(parts.map(_ => parseFloat(_) || 0).reverse(), sign);
    }
    /** Превращаем строку в миллисекунды, или null */
    static parseValue(value, format) {
        let result = TimeSpan.parse(value, format);
        return (result === null || result === undefined)
            ? null
            : result.getValue();
    }
    static parseHMmSsMs(reversedParts, sign) {
        return TimeSpan.create(reversedParts[3], reversedParts[2], reversedParts[1], reversedParts[0], sign);
    }
    static parseHMmSs(reversedParts, sign) {
        return TimeSpan.create(reversedParts[2], reversedParts[1], reversedParts[0], 0, sign);
    }
    static parseHMm(reversedParts, sign) {
        return TimeSpan.create(reversedParts[1], reversedParts[0], 0, 0, sign);
    }
    static parseMmSs(reversedParts, sign) {
        return TimeSpan.create(reversedParts[2], reversedParts[1], reversedParts[0], 0, sign);
    }
    static parseMmSsMs(reversedParts, sign) {
        return TimeSpan.create(reversedParts[3], reversedParts[2], reversedParts[1], reversedParts[0], sign);
    }
}
