//summary: Обработчики
//alias:   Ragtime.Callbacks
/** Базовый класс для Callback-ов */
export class CallbacksBase {
    constructor() {
        this._suspended = 0;
    }
    _add(value, key, once) {
        key = key || value;
        if (!this._data)
            this._data = new Map();
        this._data.set(key, value);
        if (once) {
            if (!this._once)
                this._once = new Set();
            this._once.add(key);
        }
    }
    /** Удаляем обработчик */
    remove(key) {
        this._data?.delete(key);
        this._once?.delete(key);
    }
    clear() {
        this._data = undefined;
        this._once = undefined;
    }
    _fireSync(...args) {
        if (this._suspended)
            return true;
        let success = true;
        if (this._data) {
            for (let key of this._data.keys()) {
                let handler = this._data.get(key);
                if (this._once?.has(key))
                    this.remove(key);
                try {
                    let result = handler(...args);
                    if (result === false) {
                        success = false;
                        break;
                    }
                }
                catch (e) {
                    if (this.onError) {
                        let shouldStop = this.onError(e);
                        if (shouldStop)
                            break;
                    }
                    else
                        console.error(e);
                    success = false;
                }
            }
        }
        return success;
    }
    async _fireAsync(...args) {
        if (this._suspended)
            return true;
        let success = true;
        if (this._data) {
            for (let key of this._data.keys()) {
                let handler = this._data.get(key);
                if (this._once?.has(key))
                    this.remove(key);
                try {
                    let result = await Promise.resolve(handler(...args));
                    if (result === false) {
                        success = false;
                        break;
                    }
                }
                catch (e) {
                    if (this.onError) {
                        let shouldStop = await this.onError(e);
                        if (shouldStop)
                            break;
                    }
                    else
                        console.error(e);
                    success = false;
                }
            }
        }
        return success;
    }
    suspend() {
        this._suspended += 1;
    }
    resume() {
        this._suspended -= 1;
    }
    get size() {
        return (this._data?.size ?? 0) + (this._once?.size ?? 0);
    }
}
/** Обработчики (без типов) */
export class Callbacks extends CallbacksBase {
    /** Добавляем обработчик */
    add(value, key) {
        this._add(value, key, false);
    }
    /** Добавляем однократный обработчик */
    once(value, key) {
        this._add(value, key, true);
    }
    /** Вызываем обработчики асинхронно. Если любой обработчик возвращае ===false - исполнение прерывается. */
    fireAsync(...args) {
        return this._fireAsync(...args);
    }
    /** Вызываем обработчики. Если любой обработчик возвращае ===false - исполнение прерывается. */
    fire(...args) {
        return this._fireSync(...args);
    }
}
/** Обработчики (типобезопасные) */
export class TypedCallbacks extends CallbacksBase {
    constructor() {
        super(...arguments);
        /** Вызываем обработчики асинхронно. Если любой обработчик возвращае ===false - исполнение прерывается. */
        this.fireAsync = ((...args) => {
            return this._fireAsync(...args);
        });
        /** Вызываем обработчики. Если любой обработчик возвращае ===false - исполнение прерывается. */
        this.fire = ((...args) => {
            return this._fireSync(...args);
        });
    }
    /** Добавляем обработчик */
    add(value, key) {
        this._add(value, key, false);
    }
    /** Добавляем однократный обработчик */
    once(value, key) {
        this._add(value, key, true);
    }
}
