//summary: Процедуры форматирования чисел, дат, строк, и т д
//alias:   Ragtime.Format
import { TimeSpan } from "Ragtime.TimeSpan";
import { zPad2, zPad4 } from "./Misc";
import { dateFromDto } from "Ragtime.Date";
/** Форматируем значение */
export function format(value, type, precision) {
    return (formatters[type] || none)(value, precision);
}
export function isNumber(value) {
    switch (value) {
        case "currency":
        case "fixedPoint":
        case "year":
            return true;
        default:
            return false;
    }
}
export function isBoolean(value) {
    switch (value) {
        case "boolean":
            return true;
        default:
            return false;
    }
}
export function isDate(value) {
    switch (value) {
        case "shortDate":
        case "shortDateShortTime":
        case "shortDateTime":
        case "DdMmYy_HhMm":
        case "dateHMmSsMs":
        case "dateHMmSs":
        case "dateHMm":
        case "dateMmSs":
        case "dateMmSsMs":
            return true;
        default:
            return false;
    }
}
export function isTime(value) {
    switch (value) {
        case "timeHMmSsMs":
        case "timeHMmSs":
        case "timeHMm":
        case "timeMmSs":
        case "timeMmSsMs":
            return true;
        default:
            return false;
    }
}
export function none(value) {
    return assigned(value) ? value.toString() : "";
}
export function integer(value) {
    return none(value);
}
export function currency(value, dec = 2) {
    return formatNumber(value, dec, " ");
}
export function year(value) {
    if (typeof (value) === "number")
        return formatNumber(value, 0, "");
    else if (value instanceof (Date))
        return formatNumber(value.getFullYear(), 0, "");
    else if (value?.year)
        return formatNumber(value.year, 0, "");
    else
        return value + "";
}
function getDate(value) {
    if (value === undefined || value === null)
        return null;
    else if (typeof (value) === "number")
        return new Date(value);
    else if (value instanceof Date)
        return value;
    else if (typeof (value) === "object")
        return dateFromDto(value);
    else
        return null;
}
function formatDate(value, formatter) {
    var date = getDate(value);
    if (!date)
        return "";
    else
        return formatter(date);
}
export function shortDate(value) {
    return formatDate(value, d => `${zPad2(d.getDate())}.${zPad2(d.getMonth() + 1)}.${zPad4(d.getFullYear())}`);
}
export function shortDateShortTime(value) {
    return formatDate(value, d => `${zPad2(d.getDate())}.${zPad2(d.getMonth() + 1)}.${zPad4(d.getFullYear())} ${zPad2(d.getHours())}:${zPad2(d.getMinutes())}`);
}
export function shortDateTime(value) {
    return formatDate(value, d => `${zPad2(d.getDate())}.${zPad2(d.getMonth() + 1)}.${zPad4(d.getFullYear())} ${zPad2(d.getHours())}:${zPad2(d.getMinutes())}:${zPad2(d.getSeconds())}`);
}
export function DdMmYy_HhMm(value) {
    return formatDate(value, d => `${zPad2(d.getDate())}.${zPad2(d.getMonth() + 1)}.${d.getFullYear().toString().slice(2)} ${zPad2(d.getHours())}:${zPad2(d.getMinutes())}`);
}
export function dateHMmSsMs(value) {
    return formatDate(value, d => `${zPad2(d.getHours())}:${zPad2(d.getMinutes())}:${zPad2(d.getSeconds())}.${zPad2(d.getMilliseconds())}`);
}
export function dateHMmSs(value) {
    return formatDate(value, d => `${zPad2(d.getHours())}:${zPad2(d.getMinutes())}:${zPad2(d.getSeconds())}`);
}
export function dateHMm(value) {
    return formatDate(value, d => `${zPad2(d.getHours())}:${zPad2(d.getMinutes())}`);
}
export function dateMmSs(value) {
    return formatDate(value, d => `${zPad2(d.getMinutes())}:${zPad2(d.getSeconds())}`);
}
export function dateMmSsMs(value) {
    return formatDate(value, d => `${zPad2(d.getMinutes())}:${zPad2(d.getSeconds())}.${zPad2(d.getMilliseconds())}`);
}
export function timeHMmSsMs(value) {
    if (!assigned(value))
        return "";
    else {
        var t = new TimeSpan(value);
        return `${timeSign(t)}${t.getTotalHours()}:${zPad2(t.getMinutes())}:${zPad2(t.getSeconds())}${timeOptionalMs(t)}`;
    }
}
export function timeHMmSs(value) {
    if (!assigned(value))
        return "";
    else {
        var t = new TimeSpan(value);
        return `${timeSign(t)}${t.getTotalHours()}:${zPad2(t.getMinutes())}:${zPad2(t.getSeconds())}`;
    }
}
export function timeHMm(value) {
    if (!assigned(value))
        return "";
    else {
        var t = new TimeSpan(value);
        return `${timeSign(t)}${t.getTotalHours()}:${zPad2(t.getMinutes())}`;
    }
}
export function timeMmSs(value) {
    if (!assigned(value))
        return "";
    else {
        var t = new TimeSpan(value);
        return `${timeSign(t)}${t.getTotalMinutes()}:${zPad2(t.getSeconds())}`;
    }
}
export function timeMmSsMs(value) {
    if (!assigned(value))
        return "";
    else {
        var t = new TimeSpan(value);
        return `${timeSign(t)}${t.getTotalMinutes()}:${zPad2(t.getSeconds())}${timeOptionalMs(t)}`;
    }
}
function timeSign(time) {
    return time.negative ? "-" : "";
}
function timeOptionalMs(time) {
    var ms = time.getMilliseconds();
    return !!ms
        ? "." + ms
        : "";
}
//** Форматируем дату, год - два знака */
export function formatDateWithTwoDigitsYear(value) {
    const date = getDate(value);
    if (!date)
        return "";
    return `${zPad2(date.getDate())}.${zPad2(date.getMonth() + 1)}.${date.getFullYear().toString().slice(2)} ${zPad2(date.getHours())}:${zPad2(date.getMinutes())}`;
}
export function boolean(value) {
    if (assigned(value))
        return !!value ? "да" : "нет";
    else
        return "";
}
export function fixedPoint(value, precision) {
    return formatNumber(value, precision, " ");
}
// аналог Number.toFixed, который никогда не возвращает строку в scientific notation
export function toFixedPlain(value, precision = 0) {
    let minusStr = '';
    if (value < 0) {
        value = -value;
        minusStr = '-';
    }
    const str = value.toFixed(precision);
    if (str.indexOf('e+') < 0) // scientific notation применяется только для очень больших чисел; очень маленькие остаются с кучей нулей после точки
        return minusStr + str;
    const [base, power] = str.replace('.', '').split('e+');
    return minusStr + base + Array(parseInt(power) - base.length + 2).join('0') + (precision > 0 ? '.' + Array(precision + 1).join('0') : '');
}
/**
 * Форматируем число
 * @param value: само число
 * @param n: число знаков после запятой
 * @param x: длина секции в целой части (например, для группировки по тысячам = 3)
 * @param s: разделитель секций в целой части
 * @param c: разделитель целой и дробной части
 */
export function formatNumber(value, dec, s, c = ".", x = 3) {
    if (assigned(value)) {
        value = parseFloat(value);
        if (isNaN(value))
            return "";
        else {
            var re = '\\d(?=(\\d{' + x + '})+' + (dec > 0 ? '\\D' : '$') + ')';
            var num = toFixedPlain(value, Math.max(0, ~~dec));
            if (s === null || s === undefined)
                s = " ";
            return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + s);
        }
    }
    else
        return "";
}
;
function assigned(value) {
    return value !== null && value !== undefined;
}
let formatters = {
    none,
    boolean,
    currency,
    fixedPoint,
    shortDate,
    shortDateShortTime,
    shortDateTime,
    DdMmYy_HhMm,
    dateHMmSsMs,
    dateHMmSs,
    dateHMm,
    dateMmSs,
    dateMmSsMs,
    timeHMmSsMs,
    timeHMmSs,
    timeHMm,
    timeMmSs,
    timeMmSsMs,
    year,
    integer
};
