//_@ts-nocheck
// Этот файл сгенерирован автоматически. Его НЕЛЬЗЯ РЕДАКТИРОВАТЬ.
//generated:true
//alias:Ragtime.Metadata.FieldStorage
export var DataType;
(function (DataType) {
    DataType[DataType["Undefined"] = 0] = "Undefined";
    DataType[DataType["Char"] = 1] = "Char";
    DataType[DataType["VarChar"] = 2] = "VarChar";
    DataType[DataType["Text"] = 3] = "Text";
    DataType[DataType["NChar"] = 4] = "NChar";
    DataType[DataType["NVarChar"] = 5] = "NVarChar";
    DataType[DataType["NText"] = 6] = "NText";
    DataType[DataType["Binary"] = 7] = "Binary";
    DataType[DataType["VarBinary"] = 8] = "VarBinary";
    DataType[DataType["Blob"] = 9] = "Blob";
    DataType[DataType["Image"] = 10] = "Image";
    DataType[DataType["Boolean"] = 11] = "Boolean";
    DataType[DataType["Guid"] = 12] = "Guid";
    DataType[DataType["SByte"] = 13] = "SByte";
    DataType[DataType["Int16"] = 14] = "Int16";
    DataType[DataType["Int32"] = 15] = "Int32";
    DataType[DataType["Int64"] = 16] = "Int64";
    DataType[DataType["Byte"] = 17] = "Byte";
    DataType[DataType["UInt16"] = 18] = "UInt16";
    DataType[DataType["UInt32"] = 19] = "UInt32";
    DataType[DataType["UInt64"] = 20] = "UInt64";
    DataType[DataType["Single"] = 21] = "Single";
    DataType[DataType["Double"] = 22] = "Double";
    DataType[DataType["Decimal"] = 23] = "Decimal";
    DataType[DataType["Money"] = 24] = "Money";
    DataType[DataType["SmallMoney"] = 25] = "SmallMoney";
    DataType[DataType["Date"] = 26] = "Date";
    DataType[DataType["Date32"] = 27] = "Date32";
    DataType[DataType["Time"] = 28] = "Time";
    DataType[DataType["DateTime"] = 29] = "DateTime";
    DataType[DataType["DateTime2"] = 30] = "DateTime2";
    DataType[DataType["SmallDateTime"] = 31] = "SmallDateTime";
    DataType[DataType["DateTimeOffset"] = 32] = "DateTimeOffset";
    DataType[DataType["DateTime64"] = 33] = "DateTime64";
    DataType[DataType["Timestamp"] = 34] = "Timestamp";
    DataType[DataType["Xml"] = 35] = "Xml";
    DataType[DataType["Variant"] = 36] = "Variant";
    DataType[DataType["VarNumeric"] = 37] = "VarNumeric";
    DataType[DataType["Udt"] = 38] = "Udt";
    DataType[DataType["BitArray"] = 39] = "BitArray";
    DataType[DataType["Dictionary"] = 40] = "Dictionary";
    DataType[DataType["Cursor"] = 41] = "Cursor";
    DataType[DataType["Json"] = 42] = "Json";
    DataType[DataType["BinaryJson"] = 43] = "BinaryJson";
    DataType[DataType["Structured"] = 44] = "Structured";
    DataType[DataType["Long"] = 45] = "Long";
    DataType[DataType["LongRaw"] = 46] = "LongRaw";
    DataType[DataType["Interval"] = 47] = "Interval";
    DataType[DataType["Enum"] = 48] = "Enum";
    DataType[DataType["Enum8"] = 49] = "Enum8";
    DataType[DataType["Enum16"] = 50] = "Enum16";
    DataType[DataType["BFile"] = 51] = "BFile";
    DataType[DataType["DecFloat"] = 52] = "DecFloat";
    DataType[DataType["TimeTZ"] = 53] = "TimeTZ";
    DataType[DataType["Int128"] = 54] = "Int128";
    DataType[DataType["UInt128"] = 55] = "UInt128";
    DataType[DataType["Int256"] = 56] = "Int256";
    DataType[DataType["UInt256"] = 57] = "UInt256";
    DataType[DataType["IPv4"] = 58] = "IPv4";
    DataType[DataType["IPv6"] = 59] = "IPv6";
    DataType[DataType["IntervalSecond"] = 60] = "IntervalSecond";
    DataType[DataType["IntervalMinute"] = 61] = "IntervalMinute";
    DataType[DataType["IntervalHour"] = 62] = "IntervalHour";
    DataType[DataType["IntervalDay"] = 63] = "IntervalDay";
    DataType[DataType["IntervalWeek"] = 64] = "IntervalWeek";
    DataType[DataType["IntervalMonth"] = 65] = "IntervalMonth";
    DataType[DataType["IntervalQuarter"] = 66] = "IntervalQuarter";
    DataType[DataType["IntervalYear"] = 67] = "IntervalYear";
    DataType[DataType["Decimal32"] = 68] = "Decimal32";
    DataType[DataType["Decimal64"] = 69] = "Decimal64";
    DataType[DataType["Decimal128"] = 70] = "Decimal128";
    DataType[DataType["Decimal256"] = 71] = "Decimal256";
})(DataType || (DataType = {}));
