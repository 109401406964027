//_@ts-nocheck
// Этот файл сгенерирован автоматически. Его НЕЛЬЗЯ РЕДАКТИРОВАТЬ.
//generated:true
//alias:Ragtime.Metadata.Field
export var AggFunction;
(function (AggFunction) {
    AggFunction[AggFunction["None"] = 0] = "None";
    AggFunction[AggFunction["sum"] = 1] = "sum";
    AggFunction[AggFunction["min"] = 2] = "min";
    AggFunction[AggFunction["max"] = 3] = "max";
    AggFunction[AggFunction["avg"] = 4] = "avg";
    AggFunction[AggFunction["count"] = 5] = "count";
    AggFunction[AggFunction["stdevp"] = 6] = "stdevp";
})(AggFunction || (AggFunction = {}));
export var ChartDataType;
(function (ChartDataType) {
    ChartDataType[ChartDataType["Auto"] = 0] = "Auto";
    ChartDataType[ChartDataType["excluded"] = 1] = "excluded";
    ChartDataType[ChartDataType["category"] = 2] = "category";
    ChartDataType[ChartDataType["series"] = 3] = "series";
    ChartDataType[ChartDataType["time"] = 4] = "time";
})(ChartDataType || (ChartDataType = {}));
export var FieldFulltextLookup;
(function (FieldFulltextLookup) {
    FieldFulltextLookup[FieldFulltextLookup["false"] = 0] = "false";
    FieldFulltextLookup[FieldFulltextLookup["Exact"] = 1] = "Exact";
    FieldFulltextLookup[FieldFulltextLookup["true"] = 2] = "true";
})(FieldFulltextLookup || (FieldFulltextLookup = {}));
