//summary: Низкоуровневый протокол взаимодействия между клиентской стороной (браузером) и сервером 
//alias:   Ragtime.DataService
import { TypedCallbacks as Callbacks } from "Ragtime.Callbacks";
/** Регистрируем обработчик, который будет вызываться перед вызовом сервера */
export function addBeforeRequest(handler) {
    beforeRequest.add(handler);
}
export const beforeRequest = new Callbacks();
/** Регистрируем обработчик, который будет вызываться сразу после получения ответа от сервера, до его обработки */
export function addAfterResponse(handler) {
    afterResponse.add(handler);
}
export const afterResponse = new Callbacks();
/** Регистрируем обработчик, который будет вызываться после вызова сервера - неважно, успешного или нет */
export function addAfterRequest(handler) {
    afterRequest.add(handler);
}
export const afterRequest = new Callbacks();
/** Регистрируем обработчик, который будет вызываться при ошибке */
export function addWhenFault(handler) {
    whenFault.add(handler);
}
export const whenFault = new Callbacks();
/** Вызываем сервер без контекста (вызов "статического" метода) */
export function call(service, method, args, options) {
    if (options?.postpone)
        return postponeCall(service, method, args, false, options);
    let runNow = !batched;
    if (!batched)
        beginBatch();
    let result = newCall(service, method, null, args, _ => batched.push(_), options);
    if (runNow)
        sendBatch();
    return new Promise((resolve, reject) => {
        result.then(_ => resolve(_.result), _ => reject(_));
    });
}
/** Вызываем сервер с контекстом (вызов "метода экземпляра") */
export function callWithContext(service, method, context, args, options) {
    if (options?.postpone)
        throw new Error("callWithContext: postpone option is not supported"); // Лениво было реализовывать. Леонид Белоусов, 26-июл-2023
    let runNow = !batched;
    if (!batched)
        beginBatch();
    let result = newCall(service, method, context, args, _ => batched.push(_), options);
    if (runNow)
        sendBatch();
    return result;
}
/** Вызываем сервер отложенно. Вызов будет сделан либо вместе с первым неотложенным вызовом, либо при вызове batch-а */
export function postponeCall(service, method, args, priority = false, options = null) {
    return new Promise((resolve, reject) => {
        let add = priority
            ? (_) => postponed.splice(0, 0, _)
            : (_) => postponed.push(_);
        newCall(service, method, null, args, add, options).then(_ => resolve(_.result), _ => reject(_));
    });
}
/** Начинаем пакет. После вызова этого метода ни один вызов DataService-а не пойдет на сервер до момента вызова sendBatch */
export function beginBatch(options) {
    if (!batched)
        batched = [];
    if (options?.background)
        defaultBackground = true;
}
/** Выполняем тело в контексте пакета */
export async function batch(body) {
    beginBatch();
    try {
        body();
    }
    finally {
        await sendBatch();
    }
}
/** Отправляем накопленный пакет на сервер */
export async function sendBatch() {
    let done;
    let result = new Promise((resolve, reject) => done = resolve);
    let request = {
        timezoneOffset: new Date().getTimezoneOffset(),
        items: null,
        background: false,
    };
    let response = null;
    request.items = [...postponed, ...(batched || [])];
    request.background = request.items.every(_ => _.background);
    beforeRequest.fire(request); // Это довольно важно - вызвать эти обработчики здесь. Допускаю, что какие-то из этих обработчиков вызовут postponeCall() или call().
    request.items = [...postponed, ...(batched || [])];
    request.background = request.items.every(_ => _.background);
    let cbcks = callbacks;
    batched = null;
    postponed = [];
    callbacks = {};
    defaultBackground = false;
    try {
        let fetchResult = await fetch("/$data", {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(request),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            credentials: "include",
        });
        if (fetchResult.ok) {
            response = await fetchResult.json();
            afterResponse.fire(request, response);
            if (response.fault) {
                handleError(response.fault, request.background);
                return;
            }
            try {
                for (let item of response.items) {
                    let call = cbcks[item.callId];
                    if (call) {
                        if (!item.fault) {
                            delete item.callId;
                            call.resolve(item);
                        }
                        else {
                            call.reject(item.fault.message);
                            if (!call.background)
                                whenFault.fire(item.fault);
                        }
                        try {
                            await call.promise;
                        }
                        catch {
                        }
                    }
                }
            }
            finally {
                done();
                afterRequest.fire(request, response);
            }
        }
        else
            handleError(createFault(fetchResult.status, fetchResult.statusText, await fetchResult.text()), request.background);
    }
    catch (e) {
        handleError(createFault(0, "Неожиданная ошибка", e.message), request.background);
    }
    return result;
    async function handleError(fault, background) {
        try {
            for (let id in cbcks) {
                let callback = cbcks[id];
                callback.reject(null);
                try {
                    await callback.promise;
                }
                catch {
                }
            }
            if (!background)
                whenFault.fire(fault);
        }
        finally {
            done();
            afterRequest.fire(request, response);
        }
    }
}
/** Создаем новый запрос и помещаем его в список "list" */
function newCall(service, method, context, args, add, options) {
    let call = {
        id: Object.keys(callbacks).length + 1,
        service,
        method,
        context,
        args,
        background: options?.background ?? defaultBackground,
    };
    add(call);
    let callback = {};
    callbacks[call.id] = callback;
    callback.promise = new Promise((resolve, reject) => {
        callback.resolve = resolve;
        callback.reject = reject;
    });
    callback.background = call.background;
    return callback.promise;
}
export function createFault(status, statusText, responseText) {
    let result = null;
    if (statusText && statusText !== "error") {
        result = {
            message: "Произошла неожиданная ошибка: " + statusText,
        };
    }
    if (!result) {
        if (responseText) {
            try {
                result = JSON.parse(responseText);
            }
            catch (e) {
            }
        }
    }
    if (!result) {
        result = {
            message: responseText,
        };
        if (!result.message)
            result.message = "Произошла неожиданная ошибка: " + status;
    }
    return result;
}
/** Список вызовов, которые ожидают передачи в пачке */
let batched = null;
let defaultBackground = false;
/** Список отложенных вызовов */
let postponed = [];
/** Список ответов */
let callbacks = {};
