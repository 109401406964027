//summary: Утилиты для работы с асинхронным итератором
//alias:   Ragtime.AsyncCollection
/** Вспомогательный класс для работы с асинхронным итератором */
export class AsyncCollection {
    constructor(iterable) {
        this._iterable = iterable;
    }
    static fromGenerator(generator) {
        return new AsyncCollection({
            [Symbol.asyncIterator]: generator
        });
    }
    async *[Symbol.asyncIterator]() {
        for await (const value of this._iterable) {
            yield value;
        }
    }
    async first() {
        for await (const value of this._iterable)
            return value;
        return null;
    }
    map(projection) {
        const iterable = this._iterable;
        return AsyncCollection.fromGenerator(async function* () {
            for await (const value of iterable) {
                yield projection(value);
            }
        });
    }
    filter(predicate) {
        const iterable = this._iterable;
        return AsyncCollection.fromGenerator(async function* () {
            for await (const value of iterable) {
                if (predicate(value))
                    yield value;
            }
        });
    }
    async toArray() {
        const result = [];
        for await (const value of this._iterable)
            result.push(value);
        return result;
    }
}
